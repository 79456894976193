////////////////////////////////////////////////////////////////////////
// DROPDOWN GLOBAL STYLING
////////////////////////////////////////////////////////////////////////

.dropdown-menu {
    @include scroll-bar;

    --bs-dropdown-zindex: 10000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-color: var(--gray-900);
    --bs-dropdown-bg: var(--gray-200);
    --bs-dropdown-border-color: var(--gray-500);
    --bs-dropdown-divider-bg: var(--gray-500);
    --bs-dropdown-link-hover-color: var(---gray-900);
    --bs-dropdown-link-hover-bg: var(--gray-400);
    --bs-dropdown-link-color: var(--gray-900) --bs-dropdown-link-hover-color: var(--gray-900) --bs-dropdown-link-hover-bg: var(--gray-400);
    --bs-dropdown-link-active-color: var(--priamry-light);
    --bs-dropdown-link-active-bg: var(--priamry-dark);
    --bs-dropdown-link-disabled-color: var(--gray-500);
    --bs-dropdown-header-color: var(--gray-500);
    
    box-shadow: 0px 8px 24px -4px rgba(0,0,0,0.2);
    overflow-y: auto !important;
    height: max-content !important;
    max-height: 80vh !important;
    max-width: 320px;

    .dropdown-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        // Add this class next to the 'dropdown-item' class to make item larger
        &.large {
            --bs-dropdown-item-padding-x: 1rem;
            --bs-dropdown-item-padding-y: 1rem;
        }

        // Wrap icons into this class to make better alignment
        .icon-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: auto;
        }
    }
}

////////////////////////////////////////////////////////////////////////
// END OF DROPDOWN GLOBAL STYLING
////////////////////////////////////////////////////////////////////////