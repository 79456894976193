/*
=========================================================
* Resetting Boostrap Info Classes
=========================================================
*/

.btn-info {
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn-info:disabled,
.btn-info.disabled {
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn-outline-info {
  color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn-outline-info:hover {
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  background-color: var(--bs-info);
  border-color: var(--bs-info);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: var(--bs-info);
}

.link-info {
  color: var(--bs-info);
}

.border-info {
  border-color: var(--bs-info) !important;
}

.text-info {
  color: var(--bs-info) !important;
}

.bg-info {
  background-color: var(--bs-info) !important;
  border-color: var(--bs-info) !important;
}
