.btn-primary {
  --bs-btn-color: var(--primary-light-static);
  --bs-btn-bg: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: var(--primary-light-static);
  --bs-btn-hover-bg: var(--primary-dark-static);
  --bs-btn-hover-border-color: var(--primary-dark-static);
  --bs-btn-focus-shadow-rgb: var(--primary-dark-rgb);
  --bs-btn-active-color: var(--primary-light-static);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-disabled-color: var(--primary-light-static);
  --bs-btn-disabled-bg: var(--primary);
  --bs-btn-disabled-border-color: var(--primary);
  background-color: var(--bs-btn-bg);
  color: var(--bs-btn-color);
  border-color: var(--bs-btn-border-color);
  &:hover,
  &:active,
  &:visited {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }
  &:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: 0 0 0 8px var(--bs-btn-focus-shadow-rgb);
  }
}

.btn-secondary {
  --bs-btn-color: var(--black);
  --bs-btn-bg: var(--gray-500);
  --bs-btn-border-color: var(--gray-500);
  --bs-btn-hover-color: var(--black);
  --bs-btn-hover-bg: var(--gray-600);
  --bs-btn-hover-border-color: var(--gray-600);
  --bs-btn-active-color: var(--black);
  --bs-btn-active-bg: var(--gray-600);
  --bs-btn-active-border-color: var(--gray-600);
  --bs-btn-disabled-color: var(--black);
  --bs-btn-disabled-bg: var(--gray-500);
  --bs-btn-disabled-border-color: var(--gray-500);
}

.btn-success {
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--bs-success-shadow);
  --bs-btn-hover-border-color: var(--bs-success-shadow);
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--bs-success-shadow);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-disabled-color: var(--white);
  --bs-btn-disabled-bg: var(--bs-success);
  --bs-btn-disabled-border-color: var(--bs-success);
}

.btn-danger {
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--bs-danger);
  --bs-btn-border-color: var(--bs-danger);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--bs-danger-shadow);
  --bs-btn-hover-border-color: var(--bs-danger-shadow);
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--bs-danger-shadow);
  --bs-btn-active-border-color: var(--bs-danger);
  --bs-btn-disabled-color: var(--white);
  --bs-btn-disabled-bg: var(--bs-danger);
  --bs-btn-disabled-border-color: var(--bs-danger);
}

.btn-warning {
  --bs-btn-color: var(--black);
  --bs-btn-bg: var(--bs-warning);
  --bs-btn-border-color: var(--bs-warning);
  --bs-btn-hover-color: var(--black);
  --bs-btn-hover-bg: var(--bs-warning-shadow);
  --bs-btn-hover-border-color: var(--bs-warning-shadow);
  --bs-btn-active-color: var(--black);
  --bs-btn-active-bg: var(--bs-warning-shadow);
  --bs-btn-active-border-color: var(--bs-warning);
  --bs-btn-disabled-color: var(--black);
  --bs-btn-disabled-bg: var(--bs-warning);
  --bs-btn-disabled-border-color: var(--bs-warning);
}

.btn-info {
  --bs-btn-color: var(--black);
  --bs-btn-bg: var(--bs-info);
  --bs-btn-border-color: var(--bs-info);
  --bs-btn-hover-color: var(--black);
  --bs-btn-hover-bg: var(--bs-info-shadow);
  --bs-btn-hover-border-color: var(--bs-info-shadow);
  --bs-btn-active-color: var(--black);
  --bs-btn-active-bg: var(--bs-info-shadow);
  --bs-btn-active-border-color: var(--bs-info);
  --bs-btn-disabled-color: var(--black);
  --bs-btn-disabled-bg: var(--bs-info);
  --bs-btn-disabled-border-color: var(--bs-info);
}

.btn-light {
  --bs-btn-color: var(--primary-dark-static);
  --bs-btn-bg: var(--primary-light-static);
  --bs-btn-border-color: var(--primary-light-static);
  --bs-btn-hover-color: var(--primary-light-static);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-active-color: var(--primary-light-static);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary-light-static);
  --bs-btn-disabled-color: var(--primary-dark-static);
  --bs-btn-disabled-bg: var(--primary-light-static);
  --bs-btn-disabled-border-color: var(--primary-light-static);
}

.btn-dark {
  --bs-btn-color: var(--primary-light-static);
  --bs-btn-bg: var(--primary-dark-static);
  --bs-btn-border-color: var(--primary-dark-static);
  --bs-btn-hover-color: var(--primary-light-static);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-active-color: var(--primary-light-static);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary-dark-static);
  --bs-btn-disabled-color: var(--primary-light-static);
  --bs-btn-disabled-bg: var(--primary-dark-static);
  --bs-btn-disabled-border-color: var(--primary-dark-static);
}

////////////////////////////////////////////////////////////////////////
// OUTLINE BUTTONS
////////////////////////////////////////////////////////////////////////

.btn-outline-primary {
  --bs-btn-color: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: var(--primary-light-static);
  --bs-btn-hover-bg: var(--primary-dark-static);
  --bs-btn-hover-border-color: var(--primary-dark-static);
  --bs-btn-focus-shadow-rgb: var(--primary-dark-rgb);
  --bs-btn-active-color: var(--primary-light-static);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-disabled-color: var(--primary);
  --bs-btn-disabled-bg: var(--primary);
  --bs-btn-disabled-border-color: var(--primary);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-outline-secondary {
  --bs-btn-color: var(--gray-700);
  --bs-btn-border-color: var(--gray-500);
  --bs-btn-hover-color: var(--black);
  --bs-btn-hover-bg: var(--gray-600);
  --bs-btn-hover-border-color: var(--gray-600);
  --bs-btn-active-color: var(--black);
  --bs-btn-active-bg: var(--gray-600);
  --bs-btn-active-border-color: var(--gray-600);
  --bs-btn-disabled-color: var(--gray-500);
  --bs-btn-disabled-bg: var(--gray-500);
  --bs-btn-disabled-border-color: var(--gray-500);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-outline-success {
  --bs-btn-color: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--bs-success-shadow);
  --bs-btn-hover-border-color: var(--bs-success-shadow);
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--bs-success-shadow);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-disabled-color: var(--bs-success);
  --bs-btn-disabled-bg: var(--bs-success);
  --bs-btn-disabled-border-color: var(--bs-success);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-outline-danger {
  --bs-btn-color: var(--bs-danger);
  --bs-btn-border-color: var(--bs-danger);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--bs-danger-shadow);
  --bs-btn-hover-border-color: var(--bs-danger-shadow);
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--bs-danger-shadow);
  --bs-btn-active-border-color: var(--bs-danger);
  --bs-btn-disabled-color: var(--bs-danger);
  --bs-btn-disabled-bg: var(--bs-danger);
  --bs-btn-disabled-border-color: var(--bs-danger);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-outline-warning {
  --bs-btn-color: var(--bs-warning);
  --bs-btn-border-color: var(--bs-warning);
  --bs-btn-hover-color: var(--black);
  --bs-btn-hover-bg: var(--bs-warning-shadow);
  --bs-btn-hover-border-color: var(--bs-warning-shadow);
  --bs-btn-active-color: var(--black);
  --bs-btn-active-bg: var(--bs-warning-shadow);
  --bs-btn-active-border-color: var(--bs-warning);
  --bs-btn-disabled-color: var(--bs-warning);
  --bs-btn-disabled-bg: var(--bs-warning);
  --bs-btn-disabled-border-color: var(--bs-warning);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-outline-info {
  --bs-btn-color: var(--bs-info);
  --bs-btn-border-color: var(--bs-info);
  --bs-btn-hover-color: var(--black);
  --bs-btn-hover-bg: var(--bs-info-shadow);
  --bs-btn-hover-border-color: var(--bs-info-shadow);
  --bs-btn-active-color: var(--black);
  --bs-btn-active-bg: var(--bs-info-shadow);
  --bs-btn-active-border-color: var(--bs-info);
  --bs-btn-disabled-color: var(--bs-info);
  --bs-btn-disabled-bg: var(--bs-info);
  --bs-btn-disabled-border-color: var(--bs-info);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-outline-light {
  --bs-btn-color: var(--primary-light-static);
  --bs-btn-border-color: var(--primary-light-static);
  --bs-btn-hover-color: var(--primary-dark-static);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-active-color: var(--primary-dark-static);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary-light-static);
  --bs-btn-disabled-color: var(--primary-light-static);
  --bs-btn-disabled-bg: var(--primary-light-static);
  --bs-btn-disabled-border-color: var(--primary-light-static);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-outline-dark {
  --bs-btn-color: var(--primary-dark-static);
  --bs-btn-border-color: var(--primary-dark-static);
  --bs-btn-hover-color: var(--primary-light-static);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-active-color: var(--primary-light-static);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary-dark-static);
  --bs-btn-disabled-color: var(--primary-dark-static);
  --bs-btn-disabled-bg: var(--primary-dark-static);
  --bs-btn-disabled-border-color: var(--primary-dark-static);

  &:disabled {
    background: none;
    color: var(--bs-btn-disabled-color);
  }
}

.btn-pill {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--gray-500);
  padding: 0 1rem 0 0;
  border-radius: 32px;
  color: var(--black);

  .icon {
    background: var(--primary);
    color: var(--primary-light-static);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    &.small {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &:hover {
    background: var(--gray-800);
    color: var(--gray-200);
  }

  &:disabled,
  .disabled {
    cursor: default;
    opacity: 0.5;
  }
}

////////////////////////////////////////////////////////////////////////
/// END OF OUTLINE BUTTONS
////////////////////////////////////////////////////////////////////////
/// **************************************************************** ///
/// ////////////////////////////////////////////////////////////////////
/// CUSTOM BUTTONS
////////////////////////////////////////////////////////////////////////

.button-wide {
  font-size: 2rem;
  font-weight: bold;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-link-gray {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  color: var(--gray-500);
  background: transparent;
  border: solid 1px transparent;
  padding: 0.5rem !important;

  &:hover,
  &:active,
  &.active {
    color: var(--primary-dark);
  }
}

////////////////////////////////////////////////////////////////////////
/// END OF CUSTOM BUTTONS
////////////////////////////////////////////////////////////////////////
