/*
=========================================================
* Resetting Boostrap Waring Classes
=========================================================
*/

.btn-warning {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.btn-warning:disabled,
.btn-warning.disabled {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.btn-outline-warning {
  color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.btn-outline-warning:hover {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: var(--bs-warning);
}

.link-warning {
  color: var(--bs-warning);
}

.border-warning {
  border-color: var(--bs-warning) !important;
}

.text-warning {
  color: var(--bs-warning) !important;
}

.bg-warning {
  background-color: var(--bs-warning) !important;
  border-color: var(--bs-warning) !important;
}
