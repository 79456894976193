/*
=========================================================
* Bootatrap card reset Classes
=========================================================
*/

.card {
    --bs-card-title-color: var(--text-color);
    --bs-card-subtitle-color: var(--text-color);
    --bs-card-color: var(--text-color);
    --bs-card-border-color: var(--gray-500);
    --bs-card-bg: var(--gray-100);

    .list-group-item{
        background: transparent;
    }

    .card-header {
        color: var(--heading-color);
        background-color: transparent;
    }

    .card-footer {
        color: var(--heading-color);
        background-color: var(--gray-100);
    }

    &.card-gray-100{
        background: var(--gray-100);
    }
}

.card-step {

    .accreditation-card{
        height: 100%;
        width: 100%;
    }
}
