.list-group {
    --bs-list-group-color: var(--text-color);
    --bs-list-group-bg: var(--gray-200);
    --bs-list-group-border-color: var(--gray-400);
    --bs-list-group-action-color: var(---text-color);
    --bs-list-group-action-hover-color: var(--primary-light-static);
    --bs-list-group-action-hover-bg: var(--primary-dark-static);
    --bs-list-group-action-active-color: var(--primary-light-static);
    --bs-list-group-action-active-bg: var(--primary);
    --bs-list-group-disabled-color: var(--gray-500);
    --bs-list-group-disabled-bg: var(--gray-300);
    --bs-list-group-active-color: var(--primary-light-static);
    --bs-list-group-active-bg: var(--primary);
    --bs-list-group-active-border-color: var(--gray-400);
}