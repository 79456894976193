/*
=========================================================
* Bootatrap tables reset Classes
=========================================================
*/

.table {
  --bs-table-bg: var(--gray-100);
  --bs-table-color: var(--text-color);
  --bs-table-border-color: var(--gray-500);
  --bs-table-striped-bg: var(--gray-300);

  &.table-dark {
    --bs-table-bg: var(--primary-dark-static);
    --bs-table-color: var(--primary-light-static);
    --bs-table-border-color: var(--primary-light-static);
    --bs-table-striped-bg: var(--primary);
  }

  .thead-dark{
    --bs-table-bg: var(--primary-dark-static);
    --bs-table-color: var(--primary-light-static);
  }

  .thead-light{
    --bs-table-bg: var(--primary-light-static);
    --bs-table-color: var(--primary-dark-static);
    --bs-table-striped-bg: var(--primary);
  }

  .thead-dynamic{
    --bs-table-bg: var(--primary-dark);
    --bs-table-color: var(--primary-light);
  }
}

