.sidenav-main-content {
  .card-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  a{
    font-size: calc(var(--font-size-base) * 0.8);
    margin: 0 auto 1rem;
    text-decoration: underline;
    display: inline-block;
    width: max-content;
    &:hover{
      text-decoration: none;
      font-weight: 500;
    }
  }
}

// Sidenav Slim State Top Card

.sidenav-slim{
  .sidenav-main-content {
    .card-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
    }
    a{
      font-size: calc(var(--font-size-base) * 0.8);
      text-align: center;
      text-decoration: underline;
      margin-bottom: 1rem;
      color: var(--heading-color);
    }
  }
}