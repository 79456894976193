// All modals backdrop blur
.modal-open .container-fluid,
.modal-open .container {
  filter: blur(5px) grayscale(15%);
  -webkit-filter: blur(5px) grayscale(15%);
}

.modal {
  .modal-content {
    z-index: 3000;
    background: var(--gray-100);
    font-size: var(--font-size-base);
  }
  .modal-title {
    font-weight: 400;
    font-size: var(--font-size-base);
  }

  .btn-close {
    --bs-btn-close-bg: transparent !important;
    font-size: var(--font-size-base);
    color: var(--gray-800);
    // padding: 0 !important;
    margin-right: 0.4rem;
    *{
      visibility: visible !important;
      position: relative !important;
      font-size: 24px;
      line-height: 0;
      color: var(--primary-dark);
    }
    .visually-hidden{
      visibility: visible !important;
      position: relative !important;
      line-height: 0;
      color: var(--primary-dark);
      font-size: 1.85rem !important;
    }

    &:focus{
      box-shadow: none;
      *{
        color: var(--bs-danger) !important;
      }
    }
  }

  .btn-primary-outline{
    background: none;
    border: 2px solid var(--primary);
    border-radius: 0.25rem;
    font-size: var(--font-size-base);
    font-weight: 600;
    color: var(--primary);
    padding: 1rem;
    &:hover{
        background: var(--primary);
        color: var(--primary-light);
    }
  }
  .bilten-subscription-modal{
    display: flex;
    flex-direction: row;
    align-items: center;
    .input-email{
        height: 2.5rem;
    }
  }
}

// ImageEnlargedModal

@media (min-width: 576px) {
  .image-enlarge-modal {
      max-width: 90% !important;
      margin: 1.75rem auto;
  }
  .modal-body{
      img{
          max-width: 100%;
      }
  }
}
