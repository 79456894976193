/*
=========================================================
* Bootatrap link reset Classes
=========================================================
*/
a {
  color: var(--bs-link-color);
  text-decoration: none;

  &:hover {
    filter: saturate(80%);
    cursor: pointer;
  }
}

.card-link{
  text-decoration: underline;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.link-primary {
  color: var(--primary) !important;
}

.link-primary:hover,
.link-primary:focus {
  color: var(--primary-dark) !important;
}

.link-secondary {
  color: var(--gray-500) !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: var(--gray-700) !important;
}

.link-success {
  color: var(--bs-success) !important;
}

.link-success:hover,
.link-success:focus {
  color: var(--bs-success-shadow) !important;
}

.link-info {
  color: var(--bs-info) !important;
}

.link-info:hover,
.link-info:focus {
  color: var(--bs-info-shadow) !important;
}

.link-warning {
  color: var(--bs-warning) !important;
}

.link-warning:hover,
.link-warning:focus {
  color: var(--bs-warning-shadow) !important;
}

.link-danger {
  color: var(--bs-danger) !important;
}

.link-danger:hover,
.link-danger:focus {
  color: var(--bs-danger-shadow) !important;
}

.link-light {
  color: var(--primary-light-static) !important;
}

.link-light:hover,
.link-light:focus {
  color: var(--primary) !important;
}

.link-dark {
  color: var(--primary-dark-static) !important;
}

.link-dark:hover,
.link-dark:focus {
  color: var(--primary) !important;
}

.link-primary-dark {
  color: var(--primary-dark);

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
}

.alert {
  &:has(.link-primary-dark) {
    .link-primary-dark {
      color: var(--primary);

      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
      }
    }
  }
}

// Disabled states for links
.disabled {
  &:has(.avatar-pill) {
    .avatar-pill {
      filter: grayscale(100%);
      cursor: not-allowed;
    }
  }
}