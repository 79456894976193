.form-check-label {
  cursor: pointer;
}

.form-check-input {
  cursor: pointer;

  &:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
  }
}

input[type="checkbox"]:disabled {
  appearance: none;
  border-radius: 0.25em;
  width: 1rem;
  height: 1rem;
  background: var(--gray-200);
  border: solid 1px var(--gray-400);
}

input[type='checkbox'] {
  &:focus {
    box-shadow: 0 0 0 4px rgba(var(--primary-rgb), 0.5);
  }
}

input[type='checkbox']:checked:disabled::before {
  content: '✓';
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-500);
}

////////////////////////////////////////////////////////
// Validation Checkbox
////////////////////////////////////////////////////////
// NG-VALID
input[type='checkbox'].ng-valid {
  background-color: none;
  accent-color: var(--bs-success);
  border-color: var(--bs-success);

  &:checked {
    background-color: var(--bs-success);

    &:focus {
      background-color: var(--bs-success) !important;
      box-shadow: 0 0 0 4px rgba(var(--bs-success-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 4px rgba(var(--bs-success-rgb), 0.25) !important;
  }
}

.form-check-input[type='checkbox'].ng-valid {
  background-color: none;
  accent-color: var(--bs-success);
  border-color: var(--bs-success);

  &:checked {
    background-color: var(--bs-success);

    &:focus {
      background-color: var(--bs-success) !important;
      box-shadow: 0 0 0 4px rgba(var(--bs-success-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 4px rgba(var(--bs-success-rgb), 0.25) !important;
  }
}

// End of NG-VALID

// NG-INVALID
input[type='checkbox'].ng-invalid {
  background-color: none;
  accent-color: var(--bs-danger);
  border-color: var(--bs-danger);

  &:checked {
    background-color: var(--bs-danger);

    &:focus {
      background-color: var(--bs-danger) !important;
      box-shadow: 0 0 0 4px rgba(var(--bs-danger-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 4px rgba(var(--bs-danger-rgb), 0.25) !important;
  }
}

.form-check-input[type='checkbox'].ng-invalid {
  background-color: none;
  accent-color: var(--bs-danger);
  border-color: var(--bs-danger);

  &:checked {
    background-color: var(--bs-danger);

    &:focus {
      background-color: var(--bs-danger) !important;
      box-shadow: 0 0 0 4px rgba(var(--bs-danger-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 4px rgba(var(--bs-danger-rgb), 0.25) !important;
  }
}

// End of NG-INVALID

////////////////////////////////////////////////////////
// End of Validation Checkbox
////////////////////////////////////////////////////////

input[type='Radio'] {
  &:focus {
    box-shadow: 0 0 0 3px rgba(var(--primary-rgb), 0.5);
  }
}

////////////////////////////////////////////////////////
// Validation Radio
////////////////////////////////////////////////////////
// NG-VALID
input[type='radio'].ng-valid {
  background-color: none;
  accent-color: var(--bs-success);
  border-color: var(--bs-success);

  &:checked {
    background-color: var(--bs-success);

    &:focus {
      background-color: var(--bs-success) !important;
      box-shadow: 0 0 0 3px rgba(var(--bs-success-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 3px rgba(var(--bs-success-rgb), 0.25) !important;
  }
}

.form-check-input[type='radio'].ng-valid {
  background-color: none;
  accent-color: var(--bs-success);
  border-color: var(--bs-success);

  &:checked {
    background-color: var(--bs-success);

    &:focus {
      background-color: var(--bs-success) !important;
      box-shadow: 0 0 0 3px rgba(var(--bs-success-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 3px rgba(var(--bs-success-rgb), 0.25) !important;
  }
}

// End of NG-VALID

// NG-INVALID
input[type='radio'].ng-invalid {
  background-color: none;
  accent-color: var(--bs-danger);
  border-color: var(--bs-danger);

  &:checked {
    background-color: var(--bs-danger);

    &:focus {
      background-color: var(--bs-danger) !important;
      box-shadow: 0 0 0 3px rgba(var(--bs-danger-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 3px rgba(var(--bs-danger-rgb), 0.25) !important;
  }
}

.form-check-input[type='radio'].ng-invalid {
  background-color: none;
  accent-color: var(--bs-danger);
  border-color: var(--bs-danger);

  &:checked {
    background-color: var(--bs-danger);

    &:focus {
      background-color: var(--bs-danger) !important;
      box-shadow: 0 0 0 3px rgba(var(--bs-danger-rgb), 0.25) !important;
    }
  }

  &:focus {
    background-color: var(--white) !important;
    box-shadow: 0 0 0 3px rgba(var(--bs-danger-rgb), 0.25) !important;
  }
}

// End of NG-INVALID

////////////////////////////////////////////////////////
// End of Validation Radio
////////////////////////////////////////////////////////