/*
=========================================================
* Resetting Boostrap Danger Classes
=========================================================
*/

.invalid-feedback {
  display: block;
  color: var(--bs-danger);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-danger);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-danger);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-danger);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-danger);
}

.btn-danger {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.btn-danger:disabled,
.btn-danger.disabled {
  background-color: var(--gray-500);
  border-color: var(--gray-200);
}

.btn-outline-danger {
  color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.btn-outline-danger:hover {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: var(--bs-danger);
}

.link-danger {
  color: var(--bs-danger);
}

.border-danger {
  border-color: var(--bs-danger) !important;
}

.text-danger {
  color: var(--bs-danger) !important;
}

.bg-danger {
  background-color: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
}
