.nav {
  --bs-nav-link-color: var(--text-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--gray-500);
}

.navbar-loggedout {
  background: var(--gray-100);
  min-height: 60px;

  .navbar-toggler {
    color: var(--primary-dark);
    position: relative;
    margin-top: 20px;
    transform: translateY(-50%);
  }

  .container-fluid {
    background: var(--gray-100);
  }
}

.profile-details {
  background: none;
  padding: 0;
  position: relative;

  .icon {
    margin-left: 0.5rem;
  }

  &.open {
    .icon {
      transform: rotate(-180deg);
    }
  }

  .dropdown-toggle {
    &::after {
      display: none !important;
    }
  }

  .user-details {
    display: flex;
    // flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;

    .user-profile-img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      aspect-ratio: 1/1;
    }
  }
}

.navbar-brand {
  height: 60px;
  color: var(--primary-dark);

  .logo-banner {
    max-width: 150px;
    object-fit: contain;
  }

  .logo-square {
    max-width: 60px;
    object-fit: contain;
  }

  .light-mode {
    mix-blend-mode: multiply;
  }
}

.navbar-white {
  background: var(--white) !important;
}

.navbar {
  z-index: 1000;
  height: 90px;
  box-shadow: none;
  border-bottom: 1px solid var(--gray-400);
  padding: 1rem 0 !important;

  position: relative;

  .navbar-toggler {
    align-items: center;
    justify-content: center;

    &:focus {
      box-shadow: none;
      border-color: var(--gray-500);
    }
  }


  .dropdown-menu {
    color: var(--gray-900) !important;
    background-color: var(--gray-200);
    border-color: var(--gray-400);
    right: 0 !important;
    left: auto !important;
  }

  #navbarLogging {
    &:hover {
      opacity: 0.8;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .nav-link {
    color: var(--text-color) !important;

    &:hover,
    &:focus {
      color: var(--heading-color) !important;
      opacity: 0.75;
    }
  }

  .active {
    font-weight: 900;

    &:hover,
    &:focus,
    &:active {
      color: var(--heading-color) !important;
    }
  }

  .nav-item {
    font-size: var(--font-size-base);
    padding: 0 calc(var(--padding-starter) * 0.5);

    .splitter {
      width: 1px;
      height: 32px;
      background: var(--gray-500);
    }

    .btn-nav {
      padding: calc(var(--padding-starter) / 3) var(--padding-starter);
      border-radius: 0.25rem;
    }

    @include media-breakpoint-down(md) {
      padding: calc(var(--padding-starter) * 0.5) 0;

      .splitter {
        width: 100%;
        height: 1px;
        background: var(--gray-500);
      }
    }
  }
}

@media (min-width: 767.9px) {
  .navbar {
    .navbar-nav {
      align-items: center;
    }

  }
}

@media (max-width: 767.9px) {
  .navbar {
    .dropdown-menu {
      box-shadow: none !important;
      max-width: 100% !important;
      width: 100% !important;
    }


    .profile-details {
      background: var(--gray-300);
      padding: 1rem;
      border-radius: 0.5rem;

      .dropdown-item {
        color: var(--gray-900) !important;
        &.active{
          color: var(--primary-light-static) !important;
        }
      }

      &.open {
        .icon {
          // display: block; // Show the icon when profile-details has the class 'open'
          transform: rotate(-180deg);
        }
      }

      .dropdown-toggle {
        &::after {
          display: none !important;
        }
      }

      .user-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;

        .user-profile-img {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 100%;
          aspect-ratio: 1/1;
        }
      }
    }

  }
}

@include media-breakpoint-up(sm) {
  .navbar {
    // position: relative;
    min-height: 90px;

    .logo-square {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar {
    .logo-banner {
      display: none;
    }

    .navbar-brand .logo-text {
      display: none;
    }
  }
}

.hide-logo-text {

  // used in brand management
  .logo-text {
    display: none !important;
  }
}