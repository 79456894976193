.tooltip-custom {
  position: relative;
  cursor: help;
}

.tooltip-text {
  border-bottom: 1px dotted var(--primary-dark);
}

// TOP
.tooltip-custom .tooltiptext-custom-top {
  font-size: var(--font-size-base);
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  visibility: hidden;
  background: var(--primary-dark);
  color: var(--primary-light);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  min-width: 200px;
  /* Position the tooltip */
  position: absolute;
  bottom: 65%;
  right: 50%;
  transform: translateX(50%);
  z-index: 2000;
}

.tooltip-custom:hover .tooltiptext-custom-top {
  visibility: visible;
}

// END OF TOP

// BOTTOM
.tooltip-custom .tooltiptext-custom-bottom {
  font-size: var(--font-size-base);
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  visibility: hidden;
  min-width: 200px;
  background-color: var(--primary-dark);
  color: var(--primary-light);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;

  /* Position the tooltip */
  position: absolute;
  top: 105%;
  right: 50%;
  transform: translateX(50%);
  z-index: 2000;
}

.tooltip-custom:hover .tooltiptext-custom-bottom {
  visibility: visible;
}
// END OF BOTTOM

// RIGHT
.tooltip-custom .tooltiptext-custom-right {
  font-size: var(--font-size-base);
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  visibility: hidden;
  min-width: 200px;
  background-color: var(--primary-dark);
  color: var(--primary-light);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;

  /* Position the tooltip */
  position: absolute;
  left: 102%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2000;
}

.tooltip-custom:hover .tooltiptext-custom-right {
  visibility: visible;
}
// END OF RIGHT

// LEFT
.tooltip-custom .tooltiptext-custom-left {
  font-size: var(--font-size-base);
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  visibility: hidden;
  min-width: 200px;
  background-color: var(--primary-dark);
  color: var(--primary-light);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;

  /* Position the tooltip */
  position: absolute;
  right: 102%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2000;
}

.tooltip-custom:hover .tooltiptext-custom-left {
  visibility: visible;
}
// END OF LEFT

@include media-breakpoint-down(md) {
  .tooltip-custom .tooltiptext-custom-right {
    left: 50%;
    top: 105%;
    transform: none;
    transform: translateX(-50%);
  }

  .tooltip-custom .tooltiptext-custom-left {
    right: 50%;
    top: 105%;
    transform: none;
    transform: translateX(50%);
  }
}
