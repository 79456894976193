@mixin dropdown-navbar-loggedin-styling {
  :nth-child(2) {
    margin-top: 3.2rem;
  }
  padding: 0 0.5rem;
  border: solid 1px var(--secondary-light);
  width: 15rem;
  max-height: 18.5rem;
  overflow-y: auto;
  background: var(--gray-100);
  color: var(--text-color);
  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    transform: translateX(-50%);
    background: var(--gray-200);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-600);
    border-radius: 0.2rem;
  }

  .dropdown-item {
    color: var(--gray-600);
    padding: 0.8rem 0.5rem !important;
    border-bottom: solid 1px var(--gray-400);
    border-radius: 0 !important;
    overflow-x: hidden;
    &:hover{
      color: var(--gray-200);
      background: var(--gray-800)
    }
  }
}

.dropdown-languages {
  @include dropdown-navbar-loggedin-styling;

  .search-languages {
    background: var(--gray-100);
    padding: 0.5rem 0;
    position: absolute;
    width: 92%;
    border-radius: 0;
    border-bottom: solid 1px var(--gray-400);
    z-index: 1;
  }
}

.dropdown-navbar-loggedin {
  @include dropdown-navbar-loggedin-styling;
  
  
  .dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 1.5rem;
    padding-left: 0;
    i {
      padding-top: 16px;
    }
    div {
      padding-left: 0.5rem;
      margin: 0;
      .btn-grpup {
        .btn {
          margin-top: 1rem;
        }
        .btn-primary {
          margin-left: 0;
        }
      }
      p {
        margin: 0;
      }
      .notification-title {
        font-size: var(--font-size-base);
      }
      .notification-subtitle {
        font-size: calc(var(--font-size-base) / 1.2);
        color: var(--text-400);
      }
    }
  }
  // .dropdown-header {
  //   padding: 1rem 0 0.5rem;
  //   position: absolute;
  //   width: 92%;
  //   background: var(--gray-100);
  //   border-bottom: solid 1px var(--gray-400);
  //   z-index: 1;
  //   p {
  //     margin: 0;
  //     color: var(--text-color);
  //     font-size: var(--font-size-base);
  //   }
  // }
} 

.navbar-loggedin {
  .nav-link-mobile {
    display: none;
  }
  
  .sidebar-toggle {
    padding: 0.5rem 1rem;
    background-color: var(--primary-light);
    color: var(--primary-dark);
    border: solid 1px var(--gray-400);
    border-radius: 0.5rem;
    font-size: 1rem;
    position: relative;
    margin-top: 40px;
    transform: translateY(-50%);
    &:hover{
      background-color: var(--primary-dark);
      color: var(--primary-light)
    }
  }
  .container-fluid{
    background: var(--gray-100);
  }
}

@media (max-width: 767px) {
  .navbar-loggedin {
    .sidebar-toggle {
      position: absolute;
      right: 12px;
      margin: 0;
      top: 45px;
    }

    .navbar-brand {
      color: var(--primary-dark);
      position: absolute;
      top: 14px;
      max-width: 150px;
    }

    .navbar-toggler {
      color: var(--primary-dark);
      width: 40px;
      height: 40px;
      align-self: center;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
    }
    .nav-item {
      padding: 0.5rem 0;
      .splitter {
        width: 100% !important;
        height: 1px !important;
        background-color: var(--gray-500) !important;
      }
    }

    .navbar-collapse {
      margin-top: 8vh;
    }

    .nav-link-mobile {
      display: inline-block;
    }
  }
}

@media (min-width: 1000px) {
    .navbar-expand-custom {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-custom .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-custom .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-custom .navbar-collapse {
      display: flex !important;
    }
    .navbar-expand-custom .navbar-toggler {
      display: none;
    }
}
