.badge {
    cursor: default;
    height: min-content;

    .btn-dismiss {
        color: inherit;
        // padding: 0.25rem;
        padding: 0;
        margin: 0 0.25rem;
    }


    &.disabled {
        background: var(--gray-300);
        color: var(--gray-800);
    }

    &.clickable {
        &:hover {
            cursor: pointer;
        }
    }

}


.badge {
    --bs-badge-padding-x: 0.75em;
    --bs-badge-padding-y: 0.50em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 700;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: var(--bs-border-radius);
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    // line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill{
    border-radius: 0.25rem;
}

.badge-primary {
    color: var(--primary-light-static)!important;
    background-color: RGBA(var(--primary-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--primary-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}

.badge-secondary {
    color: var(--black) !important;
    background-color: RGBA(var(--gray-500-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--gray-500-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}

.badge-success {
    color: var(--white) !important;
    background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}

.badge-info {
    color: var(--black) !important;
    background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}

.badge-warning {
    color: var(--black) !important;
    background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}

.badge-danger {
    color: var(--white) !important;
    background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}

.badge-light {
    color: var(--black) !important;
    background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}

.badge-dark {
    color: var(--white) !important;
    background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
    &.clickable {
        &:hover {
            background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 0.75)) !important;
        }
    }
}