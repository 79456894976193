// Margins

// Left

.ml-1{
    margin-left: 0.25rem  !important;
}

.ml-2{
    margin-left: 0.5rem  !important;
}

.ml-3{
    margin-left: 1rem  !important;
}

.ml-4{
    margin-left: 1.5rem  !important;
}

.ml-5{
    margin-left: 2rem  !important;
}

.ml-6{
    margin-left: 3rem !important;
}

.ml-7{
    margin-left: 4rem !important;
}

.ml-8{
    margin-left: 5rem !important;
}

.ml-9{
    margin-left: 6rem !important;
}

// Right

.mr-1{
    margin-right: 0.25rem  !important;
}

.mr-2{
    margin-right: 0.5rem  !important;
}

.mr-3{
    margin-right: 1rem  !important;
}

.mr-4{
    margin-right: 1.5rem  !important;
}

.mr-5{
    margin-right: 2rem  !important;
}

.mr-6{
    margin-right: 3rem !important;
}

.mr-7{
    margin-right: 4rem !important;
}

.mr-8{
    margin-right: 5rem !important;
}

.mr-9{
    margin-right: 6rem !important;
}

// x

.mx-1{
    margin: 0 0.25rem  !important;
}

.mx-2{
    margin: 0 0.5rem  !important;
}

.mx-3{
    margin: 0 1rem  !important;
}

.mx-4{
    margin: 0 1.5rem  !important;
}

.mx-5{
    margin: 0 2rem  !important;
}

.mx-6{
    margin: 0 3rem  !important;
}

.mx-7{
    margin: 0 4rem  !important;
}

.mx-8{
    margin: 0 5rem  !important;
}

.mx-9{
    margin: 0 6rem  !important;
}

// y

.my-1{
    margin: 0.25rem 0  !important;
}

.my-2{
    margin: 0.5rem 0  !important;
}

.my-3{
    margin: 1rem 0  !important;
}

.my-4{
    margin: 1.5rem 0  !important;
}

.my-5{
    margin: 2rem 0  !important;
}

.my-6{
    margin: 3rem 0  !important;
}

.my-7{
    margin: 4rem 0  !important;
}

.my-8{
    margin: 5rem 0  !important;
}

.my-9{
    margin: 6rem 0  !important;
}




//  top

.mt-1{
    margin-top: calc(1rem* 0.25) !important;
}

.mt-2{
    margin-top: calc(1rem* 0.50) !important;
}

.mt-3{
    margin-top: 1rem !important;
}

.mt-4{
    margin-top: calc(1rem* 1.50) !important;
}

.mt-5{
    margin-top: calc(1rem* 2) !important;
}

.mt-6{
    margin-top: 3rem !important;
}

.mt-7{
    margin-top: 4rem !important;
}

.mt-8{
    margin-top: 5rem !important;
}

.mt-9{
    margin-top: 6rem !important;
}

// bottom

.mb-1{
    margin-bottom: calc(1rem* 0.25) !important;
}

.mb-2{
    margin-bottom: calc(1rem* 0.50) !important;
}

.mb-3{
    margin-bottom: 1rem !important;
}

.mb-4{
    margin-bottom: calc(1rem* 1.50) !important;
}

.mb-5{
    margin-bottom: calc(1rem* 2) !important;
}

.mb-6{
    margin-bottom: 3rem !important;
}

.mb-7{
    margin-bottom: 4rem !important;
}

.mb-8{
    margin-bottom: 5rem !important;
}

.mb-9{
    margin-bottom: 6rem !important;
}



.text-decoration-underline{
    text-decoration: underline;
}

.float-right{
    float: right;
}

p{
    margin: 0;
}