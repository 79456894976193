.feed-with-filters{
    display: flex;
    flex-direction: row;
    .row{
        display: flex;
        flex-direction: row !important;
    }
}

@include media-breakpoint-down(md){
    .feed-with-filters{
        display: flex;
        flex-direction: column-reverse;
        .feed-card-holder{
            padding: 0;
            height: auto;
            overflow-y: hidden;
        }
        .row{
            display: flex;
            flex-direction: column;
        }
    }
  }