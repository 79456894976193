.alert-dismissible {
    padding-right: 3rem;

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding: 1.25rem 1rem;

        .visually-hidden{
            font-size: 1.85rem !important;
        }

        .sr-only{
            display: none;
        }
    }   

}