body {
  font-family: var(--dynamic-font-family);
  font-size: var(--font-size-base) !important;
  font-weight: 400;
  line-height: 1.5;
  background: var(--gray-100);
  max-height: 100vh;
}

.body-overflow-hidden{
  overflow: hidden;

  // Testing scroll bar display
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */
  // End of testing scroll bar display
}


.text-body-secondary {
  opacity: 0.8;
  color: var(--text-color) !important;
}