/*
=========================================================
* Resetting Boostrap Primary Classes
=========================================================
*/

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-range::-webkit-slider-thumb {
  background-color: var(--primary);
}

.form-range::-moz-range-thumb {
  background-color: var(--primary);
}



.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary);
  color: var(--primary-light-static);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  background-color: var(--primary);
  color: var(--primary-light);
}


.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary);
  color: var(--primary-light);
}

.page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--primary-light);
}

.progress-bar {
  background-color: var(--primary);
  color: var(--primary-light);
}

.link-primary {
  color: var(--primary);
}

.border-primary {
  border-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--primary-light);
}
