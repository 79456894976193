.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  .pagination-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    .pull-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .input-group {
      width: max-content;
      margin: 0 0.5rem;
      .curent-page {
        max-width: 2.5rem;
      }
    }
    .pull-right {
      width: content;
      .pagination-control {
        display: flex;
        flex-direction: row;
        align-items: center;
        .pager {
          margin: 0;
          padding: 0;
          margin-left: 1rem;
          .previous {
            display: inline-block;
            margin: 0;
            padding: 0 !important;
            margin-right: 1rem;
            a {
              padding: 0.5rem 1rem;
              border-radius: 0.25rem;
              --bs-btn-color: var(--primary);
              --btn-color-base: var(--white);
              color: var(--btn-color-base);
              background: var(--bs-btn-color);
              &:hover,
              &:focus,
              &:active {
                --bs-btn-color: var(--primary-dark);
                color: var(--primary-light);
              }
              &.active {
                --bs-btn-color: var(--primary-dark);
                color: var(--primary-light);
              }
            }
          }
          .next {
            display: inline-block;
            margin: 0;
            padding: 0 !important;
            a {
              padding: 0.5rem 1rem;
              border-radius: 0.25rem;
              --bs-btn-color: var(--primary);
              --btn-color-base: var(--white);
              color: var(--btn-color-base);
              background: var(--bs-btn-color);
              &:hover,
              &:focus,
              &:active {
                --bs-btn-color: var(--primary-dark);
                color: var(--primary-light);
              }
              &.active {
                --bs-btn-color: var(--primary-dark);
                color: var(--primary-light);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .pagination-input-group {
    flex-direction: column !important;
    gap: 1rem;
  }
}
