// $font-size-base
body{
    font-size: var(--font-size-base);
}

input{
    font-size: var(--font-size-base) !important;
}

// $font-size-sm
@include media-breakpoint-up(sm) { 
    body{
        font-size: calc(var(--font-size-base) * 0.875);
    }
}

// $font-size-lg
@include media-breakpoint-up(lg) { 
    body{
        font-size: calc(var(--font-size-base) * 1.25);
    }
 }

//  $display-font-sizes
.display-1{
    font-size: calc(var(--font-size-base) * 5);
}

.display-2{
    font-size: calc(var(--font-size-base) * 4.5);
}

.display-3{
    font-size: calc(var(--font-size-base) * 4);
}

.display-4{
    font-size: calc(var(--font-size-base) * 3.5);
}

.display-5{
    font-size: calc(var(--font-size-base) * 3);
}

.display-6{
    font-size: calc(var(--font-size-base) * 2.5);
}

// Headings
.h1,h1{
    font-size: calc(var(--font-size-base) * 2.5);
    font-weight: 600;
}


.h2,h2{
    font-size: calc(var(--font-size-base) * 2);
    font-weight: 600;
}

.h3,h3{
    font-size: calc(var(--font-size-base) * 1.75);
    font-weight: 600;
}

.h4,h4{
    font-size: calc(var(--font-size-base) * 1.5);
    font-weight: 600;
}

.h5,h5{
    font-size: calc(var(--font-size-base) * 1.25);
    font-weight: 600;
}

.h6,h6{
    font-size: calc(var(--font-size-base) * 1.125);
    font-weight: 600;
}


@media (max-width: 360px){
    // Headings
.h1,h1{
    font-size: calc(var(--font-size-base) * 2);
}


.h2,h2{
    font-size: calc(var(--font-size-base) * 1.75);
}

.h3,h3{
    font-size: calc(var(--font-size-base) * 1.50);
}

.h4,h4{
    font-size: calc(var(--font-size-base) * 1.25);
}

.h5,h5{
    font-size: calc(var(--font-size-base) * 1);
}

.h6,h6{
    font-size: calc(var(--font-size-base) * 0.75);
}
}

// Font Thickness

.font-weight-medium{
    font-weight: 500;
}
.font-weight-semi-bold{
    font-weight: 600;
}
.font-weight-bold{
    font-weight: 700;
}
.font-weight-extra-bold{
    font-weight: 800;
}
.font-weight-black{
    font-weight: 900;
}

// Text muted

.text-muted{
    color: var(--gray-500) !important;
}