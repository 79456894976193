/*
    CUSTOM UTILITY
    File to create custom utility classes:
    it serves to reduce the number of code repetitions in components
    and make them more flexible. 
*/

// Background

.bg-primary-dark {
    background: var(--primary-dark);
}

.bg-primary-dark-static {
    background: var(--primary-dark-static);
}

.bg-primary-light {
    background: var(--primary-light);
}

.bg-primary-light-static {
    background: var(--primary-light-static);
}

// End of background 

// Text color

.text-primary-dark {
    color: var(--primary-dark);
}

.text-primary-dark-static {
    color: var(--primary-dark-static);
}

.text-primary-light {
    color: var(--primary-light);
}

.text-primary-light-static {
    color: var(--primary-light-static);
}

// End of text color

// Traffic Light Colours

.traffic-light-0 {
    background-color: var(--traffic-light-000);
    fill: var(--traffic-light-000);

    &.stroke {
        stroke: var(--traffic-light-000);
    }
}

.traffic-light-1 {
    background-color: var(--traffic-light-100);
    fill: var(--traffic-light-100);

    &.stroke {
        stroke: var(--traffic-light-100);
    }
}

.traffic-light-2 {
    background-color: var(--traffic-light-200);
    fill: var(--traffic-light-200);

    &.stroke {
        stroke: var(--traffic-light-200);
    }
}

.traffic-light-3 {
    background-color: var(--traffic-light-300);
    fill: var(--traffic-light-300);

    &.stroke {
        stroke: var(--traffic-light-300);
    }
}

.traffic-light-4 {
    background-color: var(--traffic-light-400);
    fill: var(--traffic-light-400);

    &.stroke {
        stroke: var(--traffic-light-400);
    }
}

.traffic-light-5 {
    background-color: var(--traffic-light-500);
    fill: var(--traffic-light-500);

    &.stroke {
        stroke: var(--traffic-light-500);
    }
}

.traffic-light-6 {
    background-color: var(--traffic-light-600);
    fill: var(--traffic-light-600);

    &.stroke {
        stroke: var(--traffic-light-600);
    }
}

.traffic-light-7 {
    background-color: var(--traffic-light-700);
    fill: var(--traffic-light-700);

    &.stroke {
        stroke: var(--traffic-light-700);
    }
}

.traffic-light-8 {
    background-color: var(--traffic-light-800);
    fill: var(--traffic-light-800);

    &.stroke {
        stroke: var(--traffic-light-800);
    }
}

.traffic-light-9 {
    background-color: var(--traffic-light-900);
    fill: var(--traffic-light-900);

    &.stroke {
        stroke: var(--traffic-light-900);
    }
}

// End of Traffic Light Colours



// Aspect ratio

.ar-16-9 {
    aspect-ratio: 16/9 !important;
}

// End of aspect ratio

// Opacity hover

.opacity-hover-100{
    &:hover{
        opacity: 1 !important;
    }
}

.opacity-hover-50{
    &:hover{
        opacity: .5 !important;
    }
}

// Opacity hover

// Cards 

.card-gray-200 {
    width: 100%;
    background: var(--gray-200);
    border-radius: 1rem;
    padding: 1rem;
    transition: all 0.15s linear;
}

// end of cards 