// Flag icons

// Flag in title

.flag-align-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    p:not(.tooltiptext-custom-top, .tooltiptext-custom-bottom, .tooltiptext-custom-left, .tooltiptext-custom-right),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        line-height: normal;
        margin: 0;
        margin-right: 1rem;
    }

    img {
        margin: 0.25rem;
    }
}

.flag {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.25rem;
    border-radius: 1.5rem;
    color: var(--primary-light);

    img {
        min-width: 1.5rem;
    }

    .icon {
        display: none;
        color: var(--primary-light);
    }

    .name {
        display: none;
    }

    &:has(.primary) {

        height: 100%;
        gap: 0.5;
        background-color: var(--primary-dark);

        .icon,
        .name {
            display: inline-block;
            padding: 0 0.25rem;
        }
    }
}

// End of flag in title
// End of flag icons