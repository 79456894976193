.tab-container {
  .nav-tabs {
    .nav-link {
      border: none;
      border-bottom: 3px solid var(--gray-400);
      padding: 1rem 0.5rem;
      color: var(--gray-600);
      text-transform: none;
      font-size: var(--font-size-base);
      &:hover {
        background: transparent;
        color: var(--gray-500);
        border-color: var(--gray-500);
      }
    }
    .active {
      font-weight: 600;
      background: transparent;
      color: var(--primary-dark);
      border-color: var(--primary-dark);
      &:hover {
        color: var(--primary-dark);
        border-color: var(--primary-dark);
      }
    }
  }
}