.list-card-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .list-item-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: solid 1px var(--gray-300);
    border-radius: 0.5rem;
    background-color: var(--gray-200);
    overflow: hidden;

    &.clickable:hover {
      background-color: var(--gray-400);
      cursor: pointer;
      .open {
      transform: rotate(360deg);
      transition: all 0.5s ease-in-out;
      }
    }

    transition: all linear 0.5s;
    .header {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;

      .left {
        display: flex;
        gap: 0.5rem;
      }

      .right {
        padding: 0.5rem;
        .open {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          // margin: 8px;
          border: solid 2px var(--gray-500);
          width: 24px;
          height: 24px;
        }
      }
      .icon-holder {
        background-color: var(--primary-dark);
        color: var(--primary-light);
        border-bottom-right-radius: 0.5rem;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
      }

      .title {
        padding: 0.25rem 0;
        min-height: 32px;
        font-size: calc(var(--font-size-base) * 1.12);
        font-weight: bold;
      }
    }
    .content {
      margin-left: 32px;
      border-top: solid 1px var(--gray-500);
      height: 0;
      display: none;

      &:has(.show) {
        display: block;
        padding: 0.5rem;
        margin-right: 0.5rem;
        height: auto;
      }
    }
  }
}
