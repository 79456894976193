.sidenav-main-content {
  .trainer-card {
    .carousel {
      display: flex;
      flex-direction: column;

      .trainer-title {
        color: var(--heading-color);
        font-size: var(--font-size-base);
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
      .carousel-item {
        width: 100%;
        padding: 1rem;
        border-radius: 0.8rem;
        border: solid 1px var(--gray-400);
        background: var(--primary-light);
        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          .trainer-img {
            position: relative;
            .status-indicator{
              background: var(--bs-success);
              width: 1rem;
              height: 1rem;
              border-radius: 1rem;
              border: 2px solid var(--primary-light);
              position: absolute;
              bottom: 0;
              right: 0;

            }
            img {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: 4rem;
            }
          }
          .card-text {
            padding-left: 0.5rem;
            .title {
              color: var(--heading-color);
              font-size: calc(var(--font-size-base) * 1.2);
              line-height: calc(var(--font-size-base) * 1.2);
              font-weight: 700;
            }
            .subtitle {
              color: var(--text-color);
              font-size: calc(var(--font-size-base) * 0.8);
            }
          }
        }
      }
    }

    .carousel-control {
      color: var(--primary-dark);
      position: relative;
      align-self: center;
      height: 8px;
      width: 2.5rem;
    }

    .carousel-control-prev {
        position: absolute;
        left: auto;
        right: 2rem;
        top: 0.4rem;
        text-decoration: none;
    }
    .carousel-control-next {
      position: absolute;
      right: 0;
      top: 0.4rem;
      text-decoration: none;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon{
        width: 1rem !important;
        height: 1rem !important;
        &::after{
            font-size: 1rem !important;
        }
    }
    
  }
}

.sidenav-slim{
    .sidenav-main-content {
        .trainer-card {
          .carousel {
            display: flex;
            flex-direction: column;
      
            .trainer-title {
              color: var(--heading-color);
              font-size: var(--font-size-base);
              font-weight: 500;
              margin-bottom: 0.5rem;
            }
            .carousel-item {
              width: 100%;
              padding: 1rem;
              border-radius: 0.8rem;
              background: var(--primary-light);
              .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .trainer-img {
                  img {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 4rem;
                  }
                }
                .card-text {
                  display: none;
                }
              }
            }
          }
      
          .carousel-control {
            display: none;
          }
          
        }
      }
}
