/*
=========================================================
* Global Custom Styles for multiple sites
=========================================================
*/
/////////////////////////////////////////////////////////
// BACKDROP
/////////////////////////////////////////////////////////
.custom-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .8);
    backdrop-filter: blur(2px);
    z-index: 1000;
    display: none;
}

/////////////////////////////////////////////////////////
// END OF BACKDROP
/////////////////////////////////////////////////////////
// GLOVAL DISABLED CLASS
/////////////////////////////////////////////////////////
.disabled {
    filter: grayscale(100%);
    cursor: default;

    * {
        cursor: not-allowed;
    }

    a {
        cursor: not-allowed;
    }

    button {
        cursor: not-allowed;
    }
}

/////////////////////////////////////////////////////////
// END OF GLOVAL DISABLED CLASS
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
// Display code as a plain text box
/////////////////////////////////////////////////////////
.code-box {
    background: var(--gray-300);
    padding: 1.5rem;
    border-left: 4px solid var(--gray-800);
}

/////////////////////////////////////////////////////////
// END OF Display code as a plain text box
/////////////////////////////////////////////////////////


.rating-card-static {
    overflow: hidden;
    border-radius: 1rem;
    color: var(--text-color);
    border: solid 1px var(--gray-600);
    padding: 0 0 1rem 0;
    margin: 0.5rem 0;

    img {
        width: 100%;
        height: auto;
        margin-bottom: 0.5rem;
        border-radius: 1rem 1rem 0 0;
    }
}


.vector-map {
    position: relative;

    // Whitelabeling map
    // svg{
    //     path{
    //         fill: var(--gray-400);
    //         stroke: var(--primary-dark-static);
    //     }
    // }

    .vector-map-toolbar {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--primary);
            color: var(--primary-light-static);
            max-width: 3rem;
            max-height: 3rem;
            border-radius: 100%;
            border: solid 2px var(--primary-light-static);

            &:disabled {
                opacity: 1;
                background: var(--gray-500);
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.popover {
    opacity: 1;
    position: absolute;
    background-color: var(--gray-200);

    .popover-header {
        background-color: var(--gray-300);
        border: 1px solid var(--gray-500);
        border-bottom: 1px solid var(--gray-500);
        color: var(--text-color);
    }

    .popover-body {
        border: 1px solid var(--gray-500);
        background-color: var(--gray-200);
        color: var(--text-color);
        padding: 1rem 1rem;
    }
}


.popover-top {
    top: -8px !important;

    .popover-arrow {
        transform: translateY(-2px);

        &::before {
            border-top-color: var(--gray-500);
        }

        &::after {
            border-top-color: var(--gray-500);
        }
    }
}

.popover-bottom {
    top: 8px !important;

    .popover-arrow {
        transform: translateY(2px);
        transform: translateX(2px);

        &::before {
            border-bottom-color: var(--gray-500);
        }

        &::after {
            border-bottom-color: var(--gray-500);
        }
    }

}

.popover-right {
    left: 12px !important;

    .popover-arrow {
        left: -12px !important;
        transform: rotate(90deg);

        &::before {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
            bottom: 0;
            border-width: 0.5rem 0.5rem 0;
            border-top-color: var(--gray-500);
        }

        &::after {
            bottom: 2px;
            border-width: 0.5rem 0.5rem 0;
            border-top-color: var(--gray-500);
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }
    }
}

.popover-left {
    left: -12px !important;

    .popover-arrow {
        right: -12px !important;
        transform: rotate(-90deg);

        &::before {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
            bottom: 0;
            border-width: 0.5rem 0.5rem 0;
            border-top-color: var(--gray-500);
        }

        &::after {
            bottom: 2px;
            border-width: 0.5rem 0.5rem 0;
            border-top-color: var(--gray-500);
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }
    }
}

/////////////////////////////////////////////////////////
// This component need to be separated from this file
//*******************************************************
// Simple color picker
/////////////////////////////////////////////////////////
.simple-color-picker {
    padding: 0.5rem;
    background: var(--gray-200);
    border-radius: 1rem;

    .card {
        background: none;
        border: none;

        .header {
            border-bottom: 1px solid var(--gray-400);
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
        }

        .color-picker {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            height: 80px;
            // margin-bottom: 0.5rem;
            width: 120px;
            border-radius: 0.5rem;
            background: var(--white);
            border: solid 2px var(--gray-800);
            padding: 0;
            overflow: hidden;

            .color-picker-square {
                // border: solid 1px var(--gray-800);
                width: 100%;
                height: 100%;
                padding: 0;
            }

            .color-name {
                z-index: 1001;
                font-weight: 600;
                padding: 0.25rem 0.5rem;
            }
        }
    }

}

/////////////////////////////////////////////////////////
// END OF Simple color picker
/////////////////////////////////////////////////////////


.bell-curve-group {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    margin-top: 3rem;

    .bell-curve {
        overflow: hidden;
        position: relative;
        // if you dont like late transform function just remove it from line below
        transition: opacity 0.3s ease, transform 20ms ease-in-out;
        z-index: -10;
        height: var(--bell-height);

        .bell-position {
            transform: translateY(100%);
            transition: transform 200ms ease-in;
            position: relative;

            svg {
                position: absolute;
                bottom: 0;
                opacity: 0.75;
            }
        }

        &.active {
            .bell-position {
                transform: translateY(0);

                * {
                    animation: slime 0.5s;
                }
            }
        }
    }

    .form-bell-curve {
        position: relative;
        z-index: 100;
        margin-top: -10px;
    }
}


@keyframes slime {

    from,
    to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1, 1.1);
    }

    50% {
        transform: scale(1.1, 1);
    }

    75% {
        transform: scale(1, 1.05);
    }
}

.wrap {
    display: flex;
    flex-direction: row;
    flex: 1;
}
