/*
=========================================================
* Resetting Boostrap Succes Classes
=========================================================
*/

.valid-feedback {
  color: var(--bs-success);
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-success);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-success);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-success);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-success);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-success);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-success);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-success);
}

.btn-success {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-success:disabled,
.btn-success.disabled {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-outline-success {
  color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-outline-success:hover {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: var(--bs-success);
}

.link-success {
  color: var(--bs-success);
}

.border-success {
  border-color: var(--bs-success) !important;
}

.text-success {
  color: var(--bs-success) !important;
}

.bg-success {
  background-color: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
}
