// .carousel-card {
//   margin-top: 2rem;

//   .carousel {
//     display: flex;
//     flex-direction: column;
//     position: relative;
//     border-radius: 0.5rem;
//     border: solid 1px var(--gray-400);
//     background: var(--primary-light);
//     padding: 0.5rem 1rem 1rem 1rem;

//     .card-header {
//       padding: 0.5rem 2rem 0.5rem 0rem;
//       height: 3rem;
//       border-bottom: 1px solid var(--gray-400);
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       justify-content: space-between;

//       .carousel-title {
//         font-weight: 500;
//         color: var(--heading-color);
//         font-size: calc(var(--font-size-base) * 1.25);
//         line-height: calc(var(--font-size-base) * 1.25);
//       }

//       .carousel-index {
//         margin: 0 0.5rem;
//         color: var(--heading-color);
//         font-size: calc(var(--font-size-base) * 1.2);
//         font-weight: 600;
//       }
//     }

//     .carousel-item {
//       margin-top: 4rem;
//     }

//     .carousel-control {
//       color: var(--heading-color);
//       position: relative;
//       align-self: flex-end;
//       height: 2.5rem;
//       width: 2.5rem;

//       .icon-prev {
//         width: 0.75rem;
//       }

//       .icon-next {
//         width: 0.75rem;
//       }
//     }

//     .carousel-control-prev {
//       position: absolute;
//       left: auto;
//       right: 5.8rem;
//       top: 1rem;
//     }

//     .carousel-control-next {
//       position: absolute;
//       right: 1rem;
//       top: 1rem;
//     }
//   }

//   .slide-content {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;

//     .img-holder {
//       position: relative;
//       width: 100%;
//       cursor: pointer;

//       .main-img {
//         background-size: cover !important;
//         background-position: center !important;
//         width: 100%;
//         height: 12rem;
//         border-radius: 0.5rem;
//       }

//       .rating {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         position: absolute;
//         bottom: 0;
//         background: var(--primary);
//         width: 100%;
//         border-radius: 0.5rem;

//         * {
//           color: var(--bs-warning);
//         }

//         .rate-value {
//           font-family: var(--bs-font-sans-serif);
//           margin: 0.5rem 0.5rem 0.5rem 0;
//         }
//       }
//     }
//   }

//   .text-content {
//     .title {
//       font-size: calc(var(--font-size-base) * 1.25);
//       font-weight: 700;
//       color: var(--heading-color);

//       &:hover {
//         color: var(--bs-link-color);
//       }
//     }

//     .badges {
//       margin: 0.5rem 0;

//       * {
//         margin-right: 0.5rem;
//       }
//     }

//     .text {
//       font-size: var(--font-size-base);
//       margin-bottom: 1rem;
//     }
//   }

//   .action {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     gap: 1rem;

//     button {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       gap: 0.5rem;
//       background: var(--primary-dark);
//       font-size: var(--font-size-base);
//       font-weight: 600;
//       color: var(--primary-light);
//       border: none;
//       border-radius: 0.25rem;
//       padding: 0.5rem 1rem;

//       &:hover {
//         gap: 1rem;
//         padding-right: 0.5rem;
//       }
//     }

//     .reading-time {
//       font-size: var(--font-size-base);
//     }

//     .profile {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       gap: 1rem;

//       .profile-img {
//         background-size: cover !important;
//         background-position: center !important;
//         width: 3rem;
//         height: 3rem;
//         border-radius: 3rem;
//       }

//       .text {
//         margin: 0.5rem 0;
//         color: var(--heading-color);

//         .name {
//           font-size: var(--font-size-base);
//           font-weight: 700;
//         }

//         .position {
//           font-size: calc(var(--font-size-base) * 0.8);
//         }
//       }
//     }

//     .splitter {
//       background: var(--gray-400);
//       width: 1px;
//       height: 3rem;
//       margin: 0 0.5rem;
//     }

//     .rating {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       min-width: 85px !important;

//       * {
//         color: var(--bs-warning);
//       }

//       .rate-value {
//         font-family: var(--bs-font-sans-serif);
//         margin: 0.5rem 0.5rem 0.5rem 0;
//       }
//     }
//   }
// }

// @include media-breakpoint-down(md) {
//   .carousel-card {
//     .text-content {
//       .title {
//         margin-top: 1rem;
//       }
//     }
//   }
// }

// @media (max-width: 360px) {
//   .carousel-card {
//     .carousel {
//       .carousel-control {
//         color: var(--heading-color);
//         position: relative;
//         align-self: flex-end;
//         height: 1.5rem;
//         width: 1.5rem;

//         .carousel-control-prev-icon {
//           &::after {
//             font-size: 1rem;
//           }
//         }

//         .carousel-control-next-icon {
//           &::after {
//             font-size: 1rem;
//           }
//         }
//       }

//       .carousel-control-prev {
//         position: absolute;
//         left: 1rem;
//         top: 1.25rem;
//       }

//       .carousel-control-next {
//         position: absolute;
//         right: 1rem;
//         top: 1.25rem;
//       }

//       .card-header {
//         flex-direction: column-reverse;
//         padding-right: 0;
//         text-align: center;

//         .carousel-title {
//           font-size: var(--font-size-base);
//         }

//         .carousel-index {
//           font-size: var(--font-size-base);
//           margin: 0 0.25rem;
//           word-wrap: normal;
//         }
//       }
//     }

//     .action {
//       flex-direction: column;
//       align-items: flex-start;

//       button {
//         width: 100%;
//         padding: 1rem;

//         &:hover {
//           gap: 1rem;
//           padding-right: 0.5rem;
//         }
//       }

//       .reading-time {
//         align-self: center;
//       }

//       .splitter {
//         width: 50%;
//         height: 1px;
//         margin: 0;
//       }
//     }
//   }
// }


.carousel-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--gray-200);
  border-radius: 0.5rem;
  border: 1px solid var(--gray-500);
  gap: 1rem;
  height: 100%;

  .left,
  .right {
    display: none !important;
  }

  &.bg-light-dark {
    background-color: var(--primary-light);
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
    width: auto;
    padding: 0 0.5rem;
    color: var(--gray-800);
  }

  .header {
    border-bottom: 1px solid var(--gray-500);
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: calc(var(--font-size-base) * 1.25);
      font-weight: 500;
    }
  }

  .carousel-control {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .indicator {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .carousel-inner {
    .carousel-item {

      .img-holder {
        position: relative;
        width: 100%;
        cursor: pointer;

        .main-img {
          object-fit: cover;
          border-radius: 0.5rem 0.5rem 0 0;
        }

        .rating {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--primary);
          width: 100%;
          border-radius: 0 0 0.5rem 0.5rem;

          * {
            color: var(--bs-warning);
          }

          .rate-value {
            font-family: var(--bs-font-sans-serif);
            margin: 0.5rem 0.5rem 0.5rem 0;
          }
        }
      }

      .text-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .title {
          font-size: calc(var(--font-size-base) * 1.25);
          line-height: calc(var(--font-size-base) * 1.25);
          font-weight: 700;
          color: var(--primary-dark);

          &:hover {
            opacity: 0.8;
          }
        }

        .badges {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.25rem;
        }

        .text {
          font-size: var(--font-size-base);
          margin-bottom: 1rem;
        }
      }

      .action {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-top: 0.25rem;

        button {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 0.5rem;
          background: var(--primary-dark);
          font-size: var(--font-size-base);
          font-weight: 600;
          color: var(--primary-light);
          border: none;
          border-radius: 0.25rem;
          padding: 0.5rem 1rem;

          &:hover {
            gap: 1rem;
            padding-right: 0.5rem;
          }
        }

        .reading-time {
          font-size: var(--font-size-base);
        }
      }

      .profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        .profile-img {
          background-size: cover !important;
          background-position: center !important;
          width: 3rem;
          height: 3rem;
          border-radius: 3rem;
        }

        .text {
          margin: 0.5rem 0;
          color: var(--heading-color);

          .name {
            font-size: var(--font-size-base);
            font-weight: 700;
          }

          .position {
            font-size: calc(var(--font-size-base) * 0.8);
          }
        }
      }

      .splitter {
        background: var(--gray-400);
        width: 1px;
        height: 3rem;
        margin: 0 0.5rem;
      }

      .rating {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 85px !important;

        * {
          color: var(--bs-warning);
        }

        .rate-value {
          font-family: var(--bs-font-sans-serif);
          margin: 0.5rem 0.5rem 0.5rem 0;
        }
      }
    }

  }
}