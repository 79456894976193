.footer-bg-dark {
  .footer {
    background: var(--primary);

    * {
      color: var(--white) !important;
    }

    a {
      &:hover {
        opacity: 0.8;
      }
    }

    button {
      &:hover {
        opacity: 0.8;
      }
    }

    input {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.footer {
  background: var(--gray-200);
  padding: 2.5rem 0 0 0;

  .logo {
    span {
      svg {
        max-width: 200px;
      }
    }
  }

  .footer-heading {
    font-size: calc(var(--font-size-base) * 1.5);
    color: var(--heading-color);
  }

  ul {
    li {
      a {
        color: var(--gray-800);
        width: max-content;

        &:hover {
          color: var(--gray-500);
        }
      }
    }
  }

  .pull-right {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;

    .footer-social-media {
      display: flex;
      flex-direction: row;
      align-items: center;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0.5rem;

        span {
          display: none;
        }

        &:hover {
          span {
            display: inline-block;
          }
        }

        a {
          color: var(--text-color);
          padding: 0 0.5rem;
          font-size: 1.5rem;

          &:hover {
            color: var(--heading-color);
          }
        }
      }
    }
  }

  .theme-settings {

    .form-check {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }

    input[type="range"] {
      &::before {
        background: red;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  footer {
    .copyright {
      text-align: center;
    }

    .pull-right {
      margin-top: 1rem;
      justify-content: center;
    }
  }
}

footer {
  margin-top: auto;
  padding-top: 1rem;
}