/*
=========================================================
* Global styles order import control
=========================================================
* This file is only used to control the global import styles and their overwriting order!
*/

// Imports [ Fonts ]
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;800;900&display=swap');

// Imports [ Overrides ]
// @import 'scss/abstract/variables';
// @import 'scss/abstract/mixins';


// Font Awesome
// @import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
// @import "~@fortawesome/fontawesome-free/scss/solid.scss";
// @import "~@fortawesome/fontawesome-free/scss/regular.scss";
// @import "~@fortawesome/fontawesome-free/scss/brands.scss";

// Imports [ Vendors ]
// MDB SCSS
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/mdb.scss';

// Imports [ Whitelabeling Overrides ]
// @import 'scss/whitelabeling/success-color';
// @import 'scss/whitelabeling/info-color';
// @import 'scss/whitelabeling/warning-color';
// @import 'scss/whitelabeling/danger-color';
// @import 'scss/whitelabeling/primary-color';
// @import 'scss/whitelabeling/ng-invalid';

// Imports [ Custom SCSS ]
// @import 'scss/custom_site';
// @import 'scss/layouts/sections';

// Imports [ Custom RESETS ]
@import '../../../libs/common/src/lib/styles/main';
@import 'scss/custom_site';
// @import 'scss/resets/font-resets';
// @import 'scss/resets/default-styles';

// Imports [ Components ]
