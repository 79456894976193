.sidenav {

  // TODO  move these classes to the sidenav component if possible: 

  // // MDB Reset styles 
  // box-shadow: none; // MDB Migration 2024 - commented out by Milos
  background: var(--gray-200);
  right: 0;
  //  Custom styles
  min-height: 100vh;
  width: 360px;
  z-index: 10000;
}

@include media-breakpoint-down(sm) { 
  .sidenav{
    width: 100% !important;
  }
 }

.arrow-open{
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--primary-dark);
  margin-right: -10px;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  top: 50%;
  right: 0;
  left: 100%;
  transform: translateY(-50%);
  background: var(--gray-200) !important;
  border-radius: 2rem;
}
