.animation-pulse-infinite {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}


.animation-hover-reset{
  &:hover{
    animation: none;
  }
}
.animation-reset{
  animation: none;
}