.carousel-right-content {
    padding: 0 2.62rem 0 2rem;
    font-size: var(--font-size-base);
    h1 {
      overflow-wrap: break-word;
    }
  }
  
  @include media-breakpoint-down(lg) {
    .carousel-right-content {
      padding-left: 2rem;
      padding-right: 1.5rem;
      margin: 0;
      h1 {
        margin: 5vh 0 2vh;
      }
    }
  }

  @media (max-width: 360px){
    .carousel-right-content{
      padding: 0 0.62rem 0 1rem;
    }
  }
  