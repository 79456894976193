// Paddings

// Left

.pl-1{
    padding-left: calc(var(--padding-starter) * 0.25) !important;
}

.pl-2{
    padding-left: calc(var(--padding-starter) * 0.50) !important;
}

.pl-3{
    padding-left: var(--padding-starter) !important;
}

.pl-4{
    padding-left: calc(var(--padding-starter) * 1.50) !important;
}

.pl-5{
    padding-left: calc(var(--padding-starter) * 2) !important;
}

// Right

.pr-1{
    padding-right: calc(var(--padding-starter) * 0.25) !important;
}

.pr-2{
    padding-right: calc(var(--padding-starter) * 0.50) !important;
}

.pr-3{
    padding-right: var(--padding-starter) !important;
}

.pr-4{
    padding-right: calc(var(--padding-starter) * 1.50) !important;
}

.pr-5{
    padding-right: calc(var(--padding-starter) * 2) !important;
}

// padding x

.px-1{
    padding: 0 calc(var(--padding-starter) * 0.25) !important;
}

.px-2{
    padding: 0 calc(var(--padding-starter) * 0.50) !important;
}

.px-3{
    padding: 0 var(--padding-starter) !important;
}

.px-4{
    padding: 0 calc(var(--padding-starter) * 1.50) !important;
}

.px-5{
    padding: 0 calc(var(--padding-starter) * 2) !important;
}

// padding y

.py-1{
    padding: calc(var(--padding-starter) * 0.25) 0 !important;
}

.py-2{
    padding: calc(var(--padding-starter) * 0.50) 0 !important;
}

.py-3{
    padding: var(--padding-starter) 0 !important;
}

.py-4{
    padding: calc(var(--padding-starter) * 1.50) 0 !important;
}

.py-5{
    padding: calc(var(--padding-starter) * 2) 0 !important;
}