/*
=========================================================
* Variables
=========================================================
*/

// CSS variables

:root {
  /******************
   *  COLORS
   ******************/

  // !!DUPLICATED!! - These values are duplicated in the TypeScript. If you change a value here, you must also change the value in libs/common/src/lib/branding/default-brand.constants.ts

  // Custom Colors
  --primary: #101a5f; // !!DUPLICATED!!
  --primary-rgb: '16, 26, 95'; // !!DUPLICATED!!
  --primary-shadow: #0c1347; // !!DUPLICATED!!
  --primary-shadow-rbg: 12, 19, 71; // !!DUPLICATED!!

  --primary-dark: #070c29; // !!DUPLICATED!!
  --primary-dark-rgb: 7, 12, 41; // !!DUPLICATED!!
  --primary-dark-static: #070c29; // !!DUPLICATED!!
  --primary-dark-static-rgb: 7, 12, 41; // !!DUPLICATED!!
  --primary-dark-shadow: #05091e; // !!DUPLICATED!!
  --primary-dark-shadow-rgb: 5, 9, 30; // !!DUPLICATED!!

  --primary-light: #F9F9FF; // !!DUPLICATED!!
  --primary-light-rgb: 249, 249, 255; // !!DUPLICATED!!
  --primary-light-static: #F9F9FF; // !!DUPLICATED!!
  --primary-light-static-rgb: 249, 249, 255; // !!DUPLICATED!!
  --primary-light-shadow: #929dd5; // !!DUPLICATED!!
  --primary-light-shadow-rgb: 146, 157, 213; // !!DUPLICATED!!

  // Master color elements where the background is color primary
  --primary-master-color: #FFFFFF; // Color for elements where bg color is primary

  --secondary: #6c757d; // !!DUPLICATED!!
  --secondary-rgb: 108, 117, 125; // !!DUPLICATED!!
  --secondary-shadow: #51575d; // !!DUPLICATED!!
  --secondary-shadow-rgb: 81, 87, 93; // !!DUPLICATED!!

  --secondary-dark: #54595e; // !!DUPLICATED!!
  --secondary-dark-rgb: 84, 89, 94; // !!DUPLICATED!!
  --secondary-dark-static: #54595e; // !!DUPLICATED!!
  --secondary-dark-static-rgb: 84, 89, 94; // !!DUPLICATED!!
  --secondary-dark-shadow: #3e4246; // !!DUPLICATED!!
  --secondary-dark-shadow-rgb: 62, 66, 70; // !!DUPLICATED!!

  --secondary-light: #abb5be; // !!DUPLICATED!!
  --secondary-light-rgb: 171, 181, 190; // !!DUPLICATED!!
  --secondary-light-static: #abb5be; // !!DUPLICATED!!
  --secondary-light-static-rgb: 171, 181, 190; // !!DUPLICATED!!
  --secondary-light-shadow: #788896; // !!DUPLICATED!!
  --secondary-light-shadow-rgb: 120, 136, 150; // !!DUPLICATED!!

  // Status Colors
  --bs-success: #198754; // !!DUPLICATED!!
  --bs-success-rgb: 25, 135, 84; // !!DUPLICATED!!
  --bs-info: #0dcaf0; // !!DUPLICATED!!
  --bs-info-rgb: 13, 202, 240; // !!DUPLICATED!!
  --bs-warning: #ffc107; // !!DUPLICATED!!
  --bs-warning-rgb: 255, 193, 7; // !!DUPLICATED!!
  --bs-danger: #dc3545; // !!DUPLICATED!!
  --bs-danger-rgb: 220, 53, 69; // !!DUPLICATED!!

  // Status Shadows
  --bs-success-shadow: #12653e; // !!DUPLICATED!!
  --bs-success-shadow-rgb: 18, 101, 62; // !!DUPLICATED!!
  --bs-info-shadow: #0997b4; // !!DUPLICATED!!
  --bs-info-shadow-rgb: 9, 151, 180; // !!DUPLICATED!!
  --bs-warning-shadow: #c49300; // !!DUPLICATED!!
  --bs-warning-shadow-rgb: 196, 147, 0; // !!DUPLICATED!!
  --bs-danger-shadow: #ae1e2c; // !!DUPLICATED!!
  --bs-danger-shadow-rgb: 174, 30, 44; // !!DUPLICATED!!

  // Link Colors
  --link: #0267FD; // !!DUPLICATED!!
  --link-rgb: 2, 103, 253; // !!DUPLICATED!!
  --link-shadow: #013E98; // !!DUPLICATED!!
  --link-shadow-rgb: 1, 62, 152; // !!DUPLICATED!!
  --bs-link-color: var(--link);
  --bs-link-hover-color: var(--link-shadow);

  // Gray Scale
  --white: #FFFFFF; // !!DUPLICATED!!
  --gray-100: #F2F2F2; // !!DUPLICATED!!
  --gray-200: #e9ecef; // !!DUPLICATED!!
  --gray-300: #dee2e6; // !!DUPLICATED!!
  --gray-400: #ced4da; // !!DUPLICATED!!
  --gray-500: #adb5bd; // !!DUPLICATED!!
  --gray-600: #6c757d; // !!DUPLICATED!!
  --gray-700: #495057; // !!DUPLICATED!!
  --gray-800: #343a40; // !!DUPLICATED!!
  --gray-900: #212529; // !!DUPLICATED!!
  --black: #000000; // !!DUPLICATED!!

  --white-rgb: 255, 255, 255; // !!DUPLICATED!!
  --gray-100-rgb: 242, 242, 242; // !!DUPLICATED!!
  --gray-200-rgb: 233, 236, 239; // !!DUPLICATED!!
  --gray-300-rgb: 222, 226, 230; // !!DUPLICATED!!
  --gray-400-rgb: 206, 212, 218; // !!DUPLICATED!!
  --gray-500-rgb: 173, 181, 189; // !!DUPLICATED!!
  --gray-600-rgb: 108, 117, 125; // !!DUPLICATED!!
  --gray-700-rgb: 73, 80, 87; // !!DUPLICATED!!
  --gray-800-rgb: 52, 58, 64; // !!DUPLICATED!!
  --gray-900-rgb: 33, 37, 41; // !!DUPLICATED!!
  --black-rgb: 0, 0, 0; // !!DUPLICATED!!

  // Customs that do not exist in framework
  --heading-color: var(--gray-900); // Original definition was lightmode #666666, darkmode #FCFCFC; Gray-600 is a little lighter than the original lightmode definition
  --text-color: var(--gray-800); // Original definition was lightmode #262626, darkmode #E6E6E6

  // Traffic Light Scale
  // Update the colourService if you update this
  --traffic-light-900: #398308;
  --traffic-light-800: #4dad0e;
  --traffic-light-700: #5CCD11;
  --traffic-light-600: #91C20E;
  --traffic-light-500: #C5B808;
  --traffic-light-400: #FEAC02;
  --traffic-light-300: #FE7B00;
  --traffic-light-200: #FE4B00;
  --traffic-light-100: #FE3600;
  --traffic-light-000: #a51d1d;

  --traffic-light-900-rgb: 57, 131, 8;
  --traffic-light-800-rgb: 77, 173, 14;
  --traffic-light-700-rgb: 92, 205, 17;
  --traffic-light-600-rgb: 145, 194, 14;
  --traffic-light-500-rgb: 197, 184, 8;
  --traffic-light-400-rgb: 254, 172, 2;
  --traffic-light-300-rgb: 254, 123, 0;
  --traffic-light-200-rgb: 254, 75, 0;
  --traffic-light-100-rgb: 254, 54, 0;
  --traffic-light-000-rgb: 165, 29, 29;

  /******************
   *  END OF COLORS
   ******************/


  /******************
   *  PADDINGS
   ******************/
  // Paddings
  --padding-starter: 1rem;
  /******************
   *  END OF PADDINGS
   ******************/


  /******************
   *  MARGINS
   ******************/
  // Marigns
  --margin-starter: 1rem;
  /******************
    *  END OF MARGINS
    ******************/


  /******************
    *  FONTS
    ******************/
  --font-size-base: 1rem;
  --bs-font-sans-serif: 'Roboto', Arial, sans-serif, system-ui, -apple-system;
  --bs-font-serif: Times, sans-serif;
  --dynamic-font-family: var(--bs-font-sans-serif);
  --scale-ratio: 1;
  /******************
     *  END OF FONTS
     ******************/


  /******************
    *  CUSTOM NAVIGATION HAMBURGER BREAKPOINT
    ******************/
  --hamburger-breakpoint: 1230px;
  /******************
     *  END OF CUSTOM NAVIGATION HAMBURGER BREAKPOINT
     ******************/
}


// End of CSS variables

// scss-docs-start gray-color-map
$grays: (
  '100': var(--gray-100),
  '200': var(--gray-200),
  '300': var(--gray-300),
  '400': var(--gray-400),
  '500': var(--gray-500),
  '600': var(--gray-600),
  '700': var(--gray-700),
  '800': var(--gray-800),
  '900': var(--gray-900),
  ) !default;
// scss-docs-end gray-color-map

// scss-docs-start colors-map
$colors: (
  'blue': var(--blue),
  'indigo': var(--indigo),
  'purple': var(--purple),
  'pink': var(--pink),
  'red': var(--red),
  'orange': var(--orange),
  'yellow': var(--yellow),
  'green': var(--green),
  'teal': var(--teal),
  'cyan': var(--cyan),
  'white': var(--white),
  'gray': var(--gray-600),

  'gray-dark': var(--gray-800),
  ) !default;
// scss-docs-end colors-map

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': var(--primary),
  'primary-dark': var(--primary-dark),
  'primary-light': var(--primary-light),
  'secondary': var(--secondary),
  'secondary-dark': var(--secondary-dark),
  'secondary-light': var(--secondary-light),
  'bg-base-icp': var(--gray-100),
  'success': var(--success),
  'info': var(--info),
  'warning': var(--warning),
  'danger': var(--danger),
  'light': var(--light),
  'dark': var(--dark),
  ) !default;
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-map
$traffic-light-colors: (
  // Milos, do we need this ?
  'traffic-light-0': var(--traffic-light-000),
  'traffic-light-1': var(--traffic-light-100),
  'traffic-light-2': var(--traffic-light-200),
  'traffic-light-3': var(--traffic-light-300),
  'traffic-light-4': var(--traffic-light-400),
  'traffic-light-5': var(--traffic-light-500),
  'traffic-light-6': var(--traffic-light-600),
  'traffic-light-7': var(--traffic-light-700),
  'traffic-light-8': var(--traffic-light-800),
  'traffic-light-9': var(--traffic-light-900),
  ) !default;
// scss-docs-end theme-colors-map


// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  ) !default;

// scss-docs-end spacer-variables-maps

// scss-docs-start position-map
$position-values: (
  0: 0,
  10: 10%,
  20: 20%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  80: 80%,
  90: 90%,
  100: 100%,
  ) !default;
// scss-docs-end position-ma