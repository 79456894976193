.feed-filters {
  position: sticky;
  top: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: solid 1px var(--gray-400);
  background: var(--gray-200);
  font-size: var(--font-size-base);

  .toggle-filters {
    display: none;
  }

  .filters-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .filters-title {
      font-weight: bold;
      font-size: calc(var(--font-size-base)*1.05);
    }

    .filters-border {
      padding-bottom: 1rem;
      border-bottom: solid 1px var(--gray-500);
      margin: 1rem 0;
    }
  }

}

@include media-breakpoint-down(md) {
  .feed-filters {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    font-size: var(--font-size-base);

    .toggle-filters {
      display: block;
    }

    .filters-body {
      margin-top: 1rem;
    }
  }
}