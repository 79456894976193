.panel-group{
    overflow: hidden;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-500) ;
    .panel{
        border: none;
        .card{
            border-radius: 0;
            background: var(--gray-400);
            .card-header{
                padding: 0.25rem 0;
                border-bottom: 1px solid var(--gray-500);
                border-radius: 0;
                &:last-child{
                    border-bottom: none;
                }
                .btn-link{
                    text-decoration: none;
                    color: var(--text-color);
                }
            }
        }


        .card-body{
            background: var(--gray-200);
            padding: 1rem;
            .content-holder{
                // padding: 0 1rem;
            }
            hr{
                margin: 0;
            }
        }
    }
    
}
