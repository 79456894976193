.container-card-wrap {
    background: var(--gray-200);
    padding: 0.5rem;
    padding-right: 0.25rem;
    border-radius: 1rem;
    border: 1px solid var(--gray-400);
    
    .wrap-body {
        z-index: 2002;
        max-height: 70vh;
        padding-right: 0.5rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.3rem;
        }

        &::-webkit-scrollbar-track {
            background: var(--secondary-light);
            border-radius: 1rem;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--secondary-dark);
            border-radius: 0.2rem;
        }
    }

    .card-optional-content {
        // background: var(--gray-400);
        // border-radius: 1rem;
        margin: 1rem 1rem;
        .alert{
            margin-bottom: 0;
        }

        a{
            margin: 0;
        }

    }


    .card-optional-content-border{
        border: solid 1px var(--gray-400);
        border-radius: 1rem;
        padding: 1rem;
    }


}