.input-group {
  .input-group-text {
    color: var(--primary-light-static);
    background-color: var(--primary);
    border-color: var(--primary-light);

    &.bg-gray {
      border: solid 1px var(--gray-400);
      background: var(--gray-200);
      color: var(--text-color);
    }
  }
}

.input-self {
  // This class means we can avoid the input group being in the parent element which is absolute or fixed position in the display: inline; mode
  display: block;
  width: 100%;
}

input[type='checkbox'] {
  transform: scale(var(--scale-ratio));
}

.input-group.ng-invalid .input-group-text {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.input-group.ng-valid .input-group-text {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.form-floating {

  // .form-floating label::after {
  //   background-color: var(--gray-200);
  // }

  .form-control {
    background-color: var(--gray-200);
    border-color: var(--gray-400);
    color: var(--primary-dark);


    &:disabled {
      background-color: var(--gray-300);
      color: var(--gray-600);
    }

    &:not(:placeholder-shown){
      background-color: transparent;
    }

    &:not(:placeholder-shown)~label::after {
      background-color: var(--gray-200);
      color: var(--gray-500);
    }
    &:not(:placeholder-shown)~label::before {
      background-color: var(--gray-200);
      color: var(--gray-500);
    }
    &:not(:placeholder-shown)~label {
      background-color: transparent;
      color: var(--gray-500);
    }
  }
}

.form-control {
  background-color: var(--gray-200);
  border-color: var(--gray-400);
  color: var(--primary-dark);

  &:focus {
    color: var(--primary-dark);
    background-color: var(--gray-200);
    border-color: var(--primary);
    outline: none;
    box-shadow: 0 0 0 0.24rem rgba(var(--primary-rgb), 0.5);
  }

  &::placeholder {
    color: var(--gray-500);
  }

  &:disabled {
    background-color: var(--gray-300);
    color: var(--gray-600);
  }
}

.was-validated .form-control:valid, .form-control.ng-valid.ng-touched {
  border-color: var(--bs-success);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.ng-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.ng-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.ng-valid {
  border-color: var(--bs-success);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.ng-valid:not([multiple]):not([size]), .form-select.ng-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.ng-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}



// NG-INVALID
.was-validated .form-control:invalid, .form-control.ng-invalid.ng-touched {
  border-color: var(--bs-danger);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.ng-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.ng-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.ng-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.ng-invalid:not([multiple]):not([size]), .form-select.ng-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}


input[type=file]::file-selector-button {
  background-color: var(--gray-200) !important;
  border-color: var(--gray-400);
  color: var(--primary-dark);
}


.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-color: var(--gray-200);
  border-color: var(--gray-400);
  color: var(--primary-dark);

  &:disabled {
    background-color: var(--gray-300);
    border-color: var(--gray-400);
    color: var(--gray-600);
  }
}

.textarea-control {
  &:has(textarea) {
    display: flex;
    align-items: stretch;

    .input-group-text {
      height: inherit;
    }

    textarea.form-control {
      height: auto;
    }
  }

}

.ng-valid~.valid-feedback,
.ng-valid~.valid-tooltip {
  display: block;
}

.form-select.ng-valid.ng-touched {
  border-color: var(--bs-success);
}


.input-group {
  .input-group-text {
    &:has(.form-check-input) {
      .form-check-input {
        border: solid 1px var(--primary-light-static);
      }
    }
  }
}


.form-select {
  &:focus {
    color: var(--text-color);
    background-color: var(--gray-200);
    border-color: var(--primary);
    outline: none;
    box-shadow: 0 0 0 0.24rem rgba(var(--primary-rgb), 0.5);
  }

  option {

    &:hover,
    &:active,
    &:focus {
      background: var(--primary-dark);
      color: var(--primary-light);
    }
  }
}


.ng-invalid:focus.ng-dirty {
  color: var(--primary-dark) !important;
  background-color: var(--gray-200)  !important;
  border-color: var(--bs-danger) !important;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem var(--bs-danger-shadow) !important;
}

.ng-valid:focus {
  color: var(--primary-dark) !important;
  background-color: var(--gray-200) !important;
  border-color: var(--bs-success) !important;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem var(--bs-success-shadow) !important;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.form-label {
  color: var(--text-color);
}

// End of form Control :FOCUS

// Input with button inside
.input-group-button {
  display: flex;
  flex-direction: row;
  width: max-content;
  background: var(--primary-light-static);
  padding: 0.25rem;
  border-radius: 0.5rem;

  &:has(.invalid) {
    background: var(--bs-danger);
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark);
  }

  &:has(.ng-invalid) {
    background: var(--bs-danger);
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark);
  }

  &:has(.valid) {
    background: var(--bs-success);
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark);

  }

  &:has(.ng-valid) {
    background: var(--bs-success);
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark);
  }

  input {
    width: 100%;
    background: none;
    border: none;
    padding-left: 0.5rem;

    &::placeholder {
      color: var(--primary-dark-static);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled~button {
      background: var(--gray-500);

      &:hover {
        background: var(--gray-500) !important;
        cursor: not-allowed;
      }
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  button,
  button:not(:disabled),
  [type=button]:not(:disabled) {
    width: max-content;
    font-weight: normal;
    font-size: var(--font-size-base);
  }
}


/////////////////////////////////////////////////////
// Range input global styling
/////////////////////////////////////////////////////

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  //  slider progress trick 
  overflow: hidden;

  // Track: Webkit browsers 
  &::-webkit-slider-runnable-track {
    background: var(--gray-500);
    border-radius: 1rem;
  }

  // Track: Firefox
  &::-moz-range-track {
    background: var(--gray-500);
    border-radius: 1rem;
  }

  // Thumb: Webkit browsers
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 1rem;
    width: 1rem;
    background-color: var(--primary);
    border-radius: 50%;
    border: 2px solid var(--primary-dark);
  }

  // Thumb: Firefox
  &::-moz-range-thumb {
    height: 1rem;
    width: 1rem;
    background-color: var(--primary);
    border-radius: 50%;
    border: 2px solid var(--primary-dark);
  }

  &:focus,
  &:active {

    // Thumb: Webkit browsers
    &::-webkit-slider-thumb {
      border-radius: 25%;
      background-color: var(--primary-light);
      border: 3px solid var(--primary-dark);
      box-shadow: 0 0 0 0.25rem rgba($color: #fff, $alpha: 0.25) !important;
    }

    // Thumb: Firefox
    &::-moz-range-thumb {
      border-radius: 25%;
      background-color: var(--primary-light);
      border: 3px solid var(--primary-dark);
      box-shadow: 0 0 0 0.25rem rgba($color: #fff, $alpha: 0.25) !important;
    }
  }

  &.ng-valid,
  &.ng-invalid {
    background: none !important;

    &:focus {
      background: none !important;
      box-shadow: none !important;
    }
  }

  &:hover {

    // Thumb: Webkit browsers
    &::-webkit-slider-thumb {
      background-color: var(--primary-light);
      border: 4px solid var(--primary-dark);
      box-shadow: 0 0 0 0.25rem rgba($color: #fff, $alpha: 0.25) !important;
    }

    // Thumb: Firefox
    &::-moz-range-thumb {
      background-color: var(--primary-light);
      border: 3px solid var(--primary-dark);
      box-shadow: 0 0 0 0.25rem rgba($color: #fff, $alpha: 0.25) !important;
    }
  }
}

input[type=range]::-webkit-slider-thumb {
  border-color: var(--primary-light);
  box-shadow: 0 0 16 5px var(--black);
}

input[type=range]::-moz-slider-thumb {
  border-color: var(--primary-light);
  box-shadow: 0 0 16 5px var(--black);
}

/////////////////////////////////////////////////////
// End of Range input global styling
/////////////////////////////////////////////////////




// .input-group-button .invalid{
//   display: flex;
//   flex-direction: row;
//   width: max-content;
//   background: var(--bs-danger) !important;
//   padding: 0.25rem;
//   border-radius: 0.5rem;

//   input{
//     width: 100%;
//     background: none;
//     border: none;
//     padding-left: 0.5rem;
//     &:disabled{
//       cursor: not-allowed;
//     }
//     &:disabled ~ button{
//       background: var(--gray-500);
//       &:hover{
//         background: var(--gray-500) !important;
//         cursor: not-allowed;
//       }
//     }
//     &:focus{
//       border: none;
//       outline: none;
//     }
//   }
// }
// Input with button inside