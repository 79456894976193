// Wide card with left image position
.wide-card{
    .card{
        box-shadow: none;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background: var(--primary-light);
        overflow: hidden;
        border: solid 1px var(--gray-400);
        .card-img-left{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .card-body{
            .card-title{
                font-size: calc(var(--font-size-base)*2);
                font-weight: 700;
                color: var(--heading-color);
            }
            .card-text{
                font-size: var(--font-size-base);
            }
            .info-group{
                margin-top: 1.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                .text-badge{
                    .title{
                        font-size: calc(var(--font-size-base) * 0.8);
                    }
                    .value{
                        font-size: calc(var(--font-size-base) * 2);
                        line-height: calc(var(--font-size-base) * 2);
                        font-weight: 800;
                    }
                }
                .img-badge{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;
                    text-align: left;
                    width: min-content;
                    .title{
                        font-size: calc(var(--font-size-base) * 0.8);
                    }
                }
                .splitter{
                    background: var(--gray-400);
                    width: 1px;
                    height: 3rem;
                    margin: 0 1rem;
                }
            }
        }
    }
}

@media (max-width:360px) {
    .wide-card{
        .card{
            display: block;
        }
        .card-body{
            .card-title{
                font-size: calc(var(--font-size-base)*1.5) !important;
            }
            .info-group{
                flex-direction: column !important;
                align-items: flex-start !important;
                .splitter{
                    background: var(--gray-400);
                    height: 1px !important;
                    width: 50% !important;
                    margin: 1rem 0 !important;
                }
            }
        }
    }
}