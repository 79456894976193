.nav-reset {
    nav {
        z-index: 1001;
        background: none;
        position: fixed;
        min-height: 60px;
        top: 0;
        width: 100%;

        .nav-item,.navbar-toggler {
            color: var(--white);
            // border-color: var(--primary-light-static);
        }

        .dropdown-menu {
            .dropdown-item {
                color: var(--gray-900) !important;
                &.active{
                  color: var(--primary-light-static) !important;
                }
              }
        }

        // background: var(--primary-dark-static);


        .nav-link {
            color: var(--white) !important;

            .user-details {
                .name, .icon {
                    color: var(--gray-700);

                    &.light {
                        color: var(--white);
                    }

                }
            }

            &:hover {
                color: rgba(var(--text-color), 0.5) !important;
            }

            &:focus {
                color: var(--primary-light-static) !important;
            }
        }

        .active {
            color: var(--white) !important;
            font-weight: bold;
        }
    }
}

.nav-bg-active{
    nav {
        background: var(--primary);
        border-color: transparent;
    }
}

.nav-bg-dark {
    nav {
        position: relative !important;
        background: var(--primary);
        border-color: transparent;
    }
}


@media (max-width: 768px) {
    .nav-reset {
        nav {
            background: var(--primary);
        }

        .navbar-collapse {
            z-index: 1000;
            background: var(--primary);
            position: absolute;
            margin-top: 80px !important;
            top: 0.5rem;
            left: 0;
            padding: 1rem;
            width: 100%;
        }

        .navbar-loggedout {
            .navbar-collapse {
                z-index: 1000;
                background: var(--primary);
                position: absolute;
                // top: 90px;
                left: 0;
                padding: 1rem;
                width: 100%;
            }
        }

    }
}