.map-frame {
    border: 2px solid white;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;

    #map {
        height: 400px;
        width: 100%;
    }

    .leaflet-container {
        position: relative;
        height: 100%;

        * {
            &:focus {
                outline: none !important;
                filter: brightness(90%);
            }
        }

        // Zoom control

        .leaflet-control-container {
            position: absolute;
            right: 4.8rem;
            left: auto;
            
            .leaflet-control-attribution{
                position: absolute;
                top: 385px;
                text-wrap: nowrap !important;
                transform: translateX(-50%);
                width: max-content;
            }

            .leaflet-control-zoom {
                display: flex;
                gap: 0.5rem;
                flex-direction: column;
                border: none;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1.5rem;
                    background: var(--primary-dark-static);
                    color: var((--primary-light-static));
                    border-radius: 100% !important;
                    border: solid 1px var(--gray-500);

                    &:has(.leaflet-disabled) {
                        background: var(--gray-200) !important;
                        color: var(--gray-400) !important;
                    }
                }

                .leaflet-control-zoom-in,
                .leaflet-control-zoom-out {
                    &.leaflet-disabled {
                        background: var(--gray-200) !important;
                        color: var(--gray-500) !important;
                    }
                }

            }
        }
    }

    // End of zoom control

    // styling layers on the map
    // .leaflet-interactive{
    //     fill: var(--gray-200);
    //     stroke: var(--gray-500);

    //     &.active{
    //         fill: var(--primary);
    //         stroke: var(--primary-light-static);
    //     }
    // }
}