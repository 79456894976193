/*
=========================================================
* Global Layout Reset [ Sections ]
=========================================================
*/

// Main Section for loggedin users
// section {
//   padding-bottom: 0 !important;
// }

.main-section {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y:auto;
  overflow-x: hidden;
  @include scroll-bar;

  &.nav-offset{
    padding-top: 90px;
  }

  &:has(~.sidenav-wide){
    background: red !important;
  }

  &:has(.side-menu){
    padding-bottom: 80px;
  }
}