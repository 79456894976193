.comments {
  width: 100%;
  .comment {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;

    .img-holder {
      img {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 4rem;
        object-fit: cover;
      }
    }

    .content-holder {
      width: 100%;
      .name {
        font-size: calc(var(--font-size-base) * 1.25);
        font-weight: 700;
      }

      textarea{
        border-width: 2px;
        &:active,
        &:focus{
          box-shadow: none !important;
          border: 2px solid var(--primary-dark);
        }
      }

      .actions {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .left {
          * {
            display: inline-block;
            margin-right: 1rem;
            * {
              margin: 0;
            }
          }
        }
        .right {
          .toggle-replies {
            color: var(--gray-900);
            background: none;
            border: none;
            &:hover{
              color: var(--gray-600);
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .comments {
    .comment {
      .actions {
        display: flex;
        flex-direction: column-reverse !important;
        gap: 1rem;
        .left {
            align-self: flex-start;
          * {
            display: inline-block;
            margin-right: 1rem;
            * {
              margin: 0;
            }
          }
        }
        .right {
            align-self: flex-end;
          .toggle-replies {
            color: var(--primary);
            background: none;
            border: none;
          }
        }
      }
    }
  }
}

@media (max-width: 360px){
  .comments{
    .comment{
      flex-direction: column;
    }

    .replies{
      border-left: 2px solid var(--gray-600);
      padding-left: 1rem;
      margin-bottom: 1rem;
      hr{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}